import settingsView from '@/views/settingsView.vue'

import settingsUserView from '@/views/settings/settingsUserView.vue'
import settingsGroupsView from '@/views/settings/settingsGroupsView.vue'
import settingsMaschinesView from '@/views/settings/settingsMaschinesView.vue'

import copyMaschineWizard from '@/views/dialog-views/copy-maschine-wizard.vue'

export default {
    path: 'settings',
    name: 'settings-view',
    redirect: { name:'settingsUser'},
    component: settingsView,
    children: [
        {
            path: 'user',
            name: 'settingsUser',
            component: settingsUserView,
        },
        {
            path: 'groups',
            name: 'settingsGroups',

            component: settingsGroupsView,
        },
        {
            path: 'maschines',
            name: 'settingsMaschines',
            component: settingsMaschinesView,

            children: [ 
                 { path:'copy-dialog', name: 'settings-maschine-copy-dialog', component: copyMaschineWizard }
            ]
            
        },
    ]
}