<template>
  <div class="chart-wrapper" 
    :class="{'double': doubleWidth, 'editor-mode': display.editorMode}" 
    :style="($isMobile() && chart.ew_frame)? `background-color: ${chart.ew_frame.color};`:''">
    <div v-if="display.editorMode" style="flex: 0 0 20pt" class="drop-area" :class="{'hover-over': hoverOver}"
        @drop="onDrop" 
        @dragover.prevent
        
        @dragenter="dragEnter"
        @dragleave="dragLeave"
    ></div>

    <div class="chart vertical-wrapper" :class="{'edit':chart.edit && display.editorMode}" :draggable="display.editorMode" @dragstart.self="startDrag($event, chart)">
      
      <chart-header 
        :title="chart.name" 
        :values="value" 
        :units="unit"
        :max-values="header.max + ' ' + header.unit"
        :min-values="header.min + ' ' + header.unit"
        :avg-values="header.avg + ' ' + header.unit"

        :alert-icon="chart.ew_frame? chart.ew_frame.icon : ''"
        v-on:click="item_clicked" v-touch:touchhold="onTouchHold"
        >
        </chart-header>
     

      <md-tabs class="chart-selector" v-if="realtimeChart && !$isMobile()" v-on:md-changed="changeChartDisplay" style="flex: 0 0 auto">
        <md-tab id="realtime" md-label="Echtzeit"></md-tab>
        <md-tab id="trend" md-label="Trend"></md-tab>
      </md-tabs> 

      <md-progress-bar style="flex: 0 0 auto" v-if="loading" md-mode="query"></md-progress-bar>


      <div class="chartbox" ref="chartbox"
        :class="{'hide':$isMobile() , 'xy-scrollable':(type=='table')}"
        v-on=" (!(type =='table')) ? { click: item_clicked } : {}" style="flex: 1 0 0">

        <iframe v-if="type == 'model'" :src="iframeSrc" frameborder="0" @load="loading = false" style="height: 100%; width: 100%"></iframe>
        <div v-if="displayChartError">
          temporärer Fehler bei der Abfrage
        </div>
      </div>
      
      <div v-if="type == 'table'" ref="table-spreadsheet" style="overflow-y: scroll">
      
      </div>

      <div v-if="(tableOptions.editable && type=='table')" class="horizontal_wrapper">
        <div class="placeholder"></div>
        <md-button class="md-primary" v-on:click="add_table_row()">add</md-button>
        <div class="placeholder"></div>
      </div>
      


      <footer :class="{'hide':$isMobile() || (type == 'model')}">
        <vue-datetime type="datetime" title="from" format="yyyy-MM-dd HH:mm" v-model="from_date" v-on:change="change_date" />
        <div class="placeholder"></div>
        <md-switch v-if="this.number==0 && extraControls" v-model="for_all" class="md-primary">for all</md-switch>
        <div v-if="this.number==0" class="placeholder"></div>
        <md-field v-if="chart.type == 'bar'" style="flex: 0 0 auto; width:auto; margin-bottom: 0px;">
          <label>type</label>
          <md-select v-model="current_time_span" v-on:md-selected="change_selector">
            <md-option v-for="(item, i) in selectors" :key="i + 'selector'" :value="item.value">{{item.text}}</md-option>
          </md-select>
        </md-field>
      
        <div v-if="chart.type == 'bar'" class="placeholder"></div>
        <vue-datetime type="datetime" title="till" format="yyyy-MM-dd HH:mm" v-model="till_date" v-on:change="change_date" />
      </footer>

      <charts-details 
        ref="chart-details" 
        :show="show_details" 
        :chart="chart" 
        :maschine="maschine" 
        :graphOptions="graphOptions"
        v-on:close-details="close_details" 
       />
      
      <error-warning-frame-dialog :open="openEWFrame" :maschine="maschine" :edit-object="chart"
        v-on:close="openEWFrame = false" />
    </div>
    
  </div>
</template>

<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>

<script type="text/javascript">

import checkbox from '@/components/VueComponents/checkbox.vue'
import combobox from '@/components/VueComponents/combobox.vue'
import chartDetails from '@/components/charts-details.vue'
import chartHeaderVue from '@/components/chart-header.vue'

import jexcel from 'jexcel'
import 'jexcel/dist/jexcel.css'
import errorWarningFrameDialogVue from './error-warning-frame-dialog.vue'
import { mapState } from 'vuex'
let googleChartsReady = false;

let sleep = ms => new Promise(r => setTimeout(r, ms));
let waitFor = async function waitFor(f){
    while(!f()) await sleep(100);
    return f();
};
google.charts.load('current', {'packages':['corechart', 'timeline'], 'language': 'de'});
google.charts.setOnLoadCallback(() => googleChartsReady = true);



export default {
    name:'charts',
    props: {
        chart:Object, 
        maschine:Object, 
        number:Number, 
        edit:Boolean, 
        'self-request' :{ type:Boolean, default: false },
        'extra-controls': {type : Boolean, default: true}
    },
    components:{ 
      'checkbox':checkbox, 
      'combobox':combobox,
      'charts-details':chartDetails,
      'error-warning-frame-dialog':errorWarningFrameDialogVue,
      'chart-header':chartHeaderVue 
    }, 
    methods: {
      
      onTouchHold(){
        if (this.$isMobile()){

          this.openEWFrame = true
        }

      },
      change_date(){
        this.loading = true;
        
        this.$socket.emit('load_graph', {
          token:this.token, 
          type: this.chart.type, ID:this.graph.ID, 
          maschine:this.maschine,

          graph: this.chart,
          html_id: this.chart.html_id,

          time:
            {von:this.chart.from, bis:this.chart.till, span: (this.type=='bar')? this.current_time_span: null}, 
          mobil:this.$isMobile(), 
          maschine: {id: parseInt(this.maschine.id)}
        })
      },
      split_selected(item){
        this.current_time_span = item.text;
        this.$socket.emit('load_graph', {
          token:this.token, 
          type:this.type, ID:this.graph.ID, 
          graph: this.graph,
          html_id: this.chart.html_id,

          time:{von:this.from_date, bis:this.till_date, span: (this.type=='bar')? this.current_time_span: null}, mobil:this.$isMobile(), maschine:{id: parseInt(this.maschine.id)}})

      },
     
      item_clicked(){

        if (!this.edit && this.type != 'table'){
          this.show_details = true;
          this.$emit('item-clicked', '')
        }
        else{
          this.$emit('item-clicked', '')

        }
      },
      close_details(){
        this.show_details = false;
      },
      change_selector(){
        this.change_date()
      
        if(this.number == 0 && this.for_all)
          this.$emit('change_date', {from:this.from_date, till:this.till_date})

      },
      add_table_row(){
        const valuesCount = this.tableOptions.valuenames.length 
        if(this.jtable.getData().length)
          this.jtable.insertRow([this.$moment().format('YYYY-MM-DD HH:mm'), ...Array(valuesCount).fill('') ])
        else 
          this.jtable.setData([[this.$moment().format('YYYY-MM-DD HH:mm'), ...Array(valuesCount).fill('') ]])
      },

      chart_loaded(){
        this.loading = false;
      },
      displayUnit(index){
        if(index + 1 >= this.unit.length)
          return this.unit[index]? this.unit[index]: ''
        
        return this.unit[index + 1] == this.unit[index]? '': (this.unit[index]? this.unit[index]: '')
      },

      startDrag(e, item){
        e.dataTransfer.dropEffect = 'move'
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('dataKey', item.html_id)
      },
      onDrop(e){
        const key = e.dataTransfer.getData('dataKey')
        this.hoverOver = false
        this.$emit('reorder-chart', {key: key, order: this.chart.reihenfolge})

      },
      dragEnter(e){
        e.preventDefault()
        this.hoverOver = true
      }, 
      dragLeave(e){
        e.preventDefault()
        this.hoverOver = false
      },
      changeChartDisplay(e){
        console.log(e);
        this.chartDisplay = e;

        if(e == 'trend'){
          this.drawLineChart(this.line_chart.data, this.line_chart.options)
        }
      },


      drawLineChart(data, options){
        if(!data){
          return;
        }

        const chartbox = this.$refs.chartbox
        options.legend = 'none';


        options.crosshair = { 
          trigger: 'both', 
          orientation: 'vertical', 
          color: '#ccc' 
        }
        options.height = chartbox.getBoundingClientRect().height ;
        options.width = chartbox.getBoundingClientRect().width; 
        options.chartArea = {  left: 100, right: 30, width: options.width - 100 }

        const chartV = new google.visualization.LineChart(chartbox);
        google.visualization.events.addListener(chartV, 'ready', this.chart_loaded);

          
        chartV.draw(data, options);
        this.chartObject = chartV;
      },
      changeChartDetailsDisplay(e){

        
        this.$refs['chart-details'].drawLineChart(this.line_chart.data, this.line_chart.options)

      }

      
    },
    sockets:{
      setup_graph_header({html_id, value, unit, type, valuenames, options}){
        if(this.chart.html_id != html_id)
          return;

        if(!Array.isArray(value)){
          value = [ value ]
        }
        this.valuenames = valuenames;
        //this.doubleWidth = value.length > 1
        if (type == 'status'){
          this.value = [valuenames.filter((e, i) => parseInt(value[i])).join(', ')]
        }
        else{
          this.value = value
          this.unit = unit
        }

        this.chart.value = value;

        this.graphOptions = options;

        if(this.graphOptions.realtime)
          this.$store.commit('display/set-realtime-option', true)


      },
      setup_graph_footer({html_id, graph}){
        if(this.chart.html_id != html_id)
          return;
        this.graph = graph
        this.graph.id = graph.ID
      
        this.graph.max = 0;
        this.graph.min = 0;
        this.graph.avg = 0;

      },
      update_graph_header({html_id, value,}){
        if(this.chart.html_id != html_id)
          return;

        if(!Array.isArray(value)){
          value = [ value ]
        }
        //this.doubleWidth = value.length > 1
        if (this.chart.type == 'status'){
          this.value = [this.valuenames.filter((e, i) => parseInt(value[i])).join(', ')]
        }else
          this.value = value

        this.chart.value = value;

        if(this.realtimeChart){
          this.realtimeDataBuffer.push([new Date(), ...(value.map(e => parseFloat(e))  )])

          const data = google.visualization.arrayToDataTable( this.realtimeDataBuffer );

          if(this.chartDisplay == 'realtime'){
            this.drawLineChart(data, this.line_chart.options)

          }

          if(this.show_details){
            this.$refs['chart-details'].drawLineChart(data, this.line_chart.options, 'realtime')
          }

        }

      },
      setup_graph_header_mobil({html_id, max_value, min_value, avg_value, unit, chart}){
        if(this.chart.html_id != html_id)
          return;

        if(max_value !== undefined)
          this.header.max = max_value
        if(min_value !== undefined)
          this.header.min = min_value
        if(avg_value !== undefined)
          this.header.avg = avg_value
        this.unit = unit

        this.valuenames = chart.valuenames
      
      },
      'add_line_chart': async function({html_id, dataTable, options, show_details}){
        if(this.chart.html_id != html_id || show_details)
          return;
        await waitFor(() => googleChartsReady)

        this.type = 'line'
        this.line_chart.options = options;

        this.realtimeDataBuffer = [dataTable[0]]

        this.$nextTick( () => {

          const data = google.visualization.arrayToDataTable(
            dataTable.map((row, i) => i? row.map((item, index) => (index)? item:new Date(item)  ): row )
            );
          
          this.line_chart.data = data;

          if(dataTable.length <= 2)
            return

          this.drawLineChart(data, options)

          
        })

      
      },
      'add_bar_chart': async function({html_id, dataTable, options, show_details}){
        if(this.chart.html_id != html_id || show_details)
          return;
        await waitFor(() => googleChartsReady)
        
        this.type = 'bar'
        this.$nextTick( () => {

          const chartbox = this.$refs.chartbox

          options.height = chartbox.getBoundingClientRect().height ;
          options.width = chartbox.getBoundingClientRect().width; 
          options.chartArea = {  left: 100, right: 30, width: options.width - 100 }

          const data = google.visualization.arrayToDataTable(dataTable.map((row, i) => {
            if(i)
              return row.map((c, i) => i? c: new Date(c))
            else
              return row
            }) 
          );

          const chartV = new google.visualization.ColumnChart(chartbox);
          google.visualization.events.addListener(chartV, 'ready', this.chart_loaded);


          chartV.draw(data, options); 

          this.bar_chart.active = true
        })
      },
      'add_status_chart': async function({html_id, dataTable, options, show_details}){
        if(this.chart.html_id != html_id || show_details)
          return;
        await waitFor(() => googleChartsReady)

        this.type = 'status'
        const chart = new google.visualization.Timeline(this.$refs.chartbox);
        const data = new google.visualization.DataTable();
        data.addColumn({ type: 'string', id: 'name' });

        data.addColumn({ type: 'string', id: 'name' });
        data.addColumn({ type: 'string', id: 'style', role: 'style' });
        data.addColumn({ type: 'date', id: 'start' });
        data.addColumn({ type: 'date', id: 'stop' });
        const dt = dataTable.map(item => [`${item.name}`, item.name, item.color, new Date(item.start), new Date(item.stop)])
        data.addRows( dt );
        
        options.hAxis.maxValue = new Date(options.hAxis.maxValue)
        options.hAxis.minValue = new Date(options.hAxis.minValue)

        this.status_chart.options = options;
        
        google.visualization.events.addListener(chart, 'ready', this.chart_loaded);


        chart.draw(data, this.status_chart.options)

      },
      'add_model': function ({ html_id, options, show_details }) {
        if(this.chart.html_id != html_id || show_details)
          return;
        this.type = 'model'


        this.model_identifier = options.key_identifier

        const url = `${this.$hostname}/model/${options.name}/chart/${this.model_identifier}`

        fetch(url, {
          headers: {
            "Content-Type": "text/html",
            "Authorization": this.token
          },
        }).then(async e => {
          const html = await e.text()
          this.iframeSrc =  "ldata:text/html;charset=utf-8," + escape(html);
        })

        this.iframeSrc = ''
      },

      setup_graph_footer_mobil({html_id, max_value, min_value, avg_value, unit}){
        if(this.chart.html_id != html_id)
          return;

        if(max_value !== undefined)
          this.header.max = max_value
        if(min_value !== undefined)
          this.header.min = min_value
        if(avg_value !== undefined)
          this.header.avg = avg_value
        this.header.unit = unit
      }, 
      'add_table_chart':function({html_id, header, dataTable, table_ids}){
        if(this.chart.html_id != html_id)
          return;
        this.type = 'table'

        this.tableOptions.editable = header.some(e => e.editable) 
        this.tableOptions.valuenames = header;
        this.tableOptions.ids = table_ids;

        
        this.$nextTick(()=> {
          this.$refs["table-spreadsheet"].innerHTML = ''
          const columnWidth = (this.$refs["table-spreadsheet"].clientWidth - (170 + 44 + 25)) / header.length
          const valuesCount = this.tableOptions.valuenames.length 
          const options = {
            columns:[ { title:'Date Time', width:170 }, ...header.map(e =>{ return{title: e.name + `[${e.unit}]`, width: columnWidth}} ) ],
            data: [ [this.$moment().format('YYYY-MM-DD HH:mm'), ...Array(valuesCount).fill('0')] ]
          }

          this.jtable = jexcel(this.$refs["table-spreadsheet"], options);
          this.loading = false
          if(dataTable.length)
            this.jtable.setData(dataTable)

        })
      },
      'add_chart_error': function({ html_id }) {
        if(this.chart.html_id != html_id || show_details)
          return;

        this.displayChartError = true
        this.loading = false;
      }
    },
    data(){
      const vm = this;
      const today = new Date().toISOString()
      let yesterday = new Date()
      const day_offset = (this.$props.chart.type=='bar')? 7:1;

      yesterday.setDate(yesterday.getDate() - day_offset)
      yesterday = yesterday.toISOString()
      this.$props.chart.save = function(){
        vm.$socket.emit('save-graph-details', {token:vm.token, maschine:vm.$props.maschine, chart:vm.$props.chart})
      }
      if(!this.chart.from)
        this.chart.from = yesterday;
      if(!this.chart.till)
        this.chart.till = today;

      return{
        chartDisplay: 'trend',

        loading: true && !this.$isMobile(),
        doubleWidth: false,

        value: [],
        unit: '',
        valuenames: [],
        from_date: this.chart.from,
        till_date: this.chart.till,
        for_all: false,
        header: {max:'', min:'', avg: '', unit:''},

        graph: {}, type: '',
        

        line_chart: {data: [], options:[], active:false},
        bar_chart: {data: [], options:[], active:false},
        status_chart: {data: [], options: [], active:false},
        

        selector: 'daily',
        selectors: [{value: 'hourly', text:'Stunde'}, {value: 'daily', text:'Tag'}, {value: 'weekly', text:'Woche'}, {value: 'monthly', text:'Monat'}, {value: 'yearly', text:'Jahr'}],

        time_splits: [{text:'Tag', active:false}, {text:'Woche', active: true}, {text:'Monat', active: false}, {text:'Jahr', active:false}],
        current_time_span: 'daily',
        show_details: false,

        //Table
        tableOptions: {
          editable:true,
          valuenames: [],
          ids: []
        },
        jtable: {},

        openEWFrame:false,

        hoverOver: false,
        model_identifier: '',

        iframeSrc: '',

        displayChartError: false,

        graphOptions: {},

        realtimeDataBuffer: []
      }
    },
    watch:{
      from_date(){
        if(this.from_date.toISOString)
          this.chart.from = this.from_date.toISOString();
        else
          this.chart.from = this.from_date;
      
        this.change_date()
        if(this.number == 0 && this.for_all)
          this.$emit('change-date', {from:this.from_date, till:this.till_date})

      },
      till_date(){
        if(this.till_date.toISOString)
          this.chart.till = this.till_date.toISOString();
        else
          this.chart.till = this.till_date;

        this.change_date()
        
        if(this.number == 0 && this.for_all)
          this.$emit('change-date', {from:this.from_date, till:this.till_date})

      },

      realtimeChart(){
        if(this.realtimeChart){
          this.chartDisplay = 'realtime'
        }else{// execute when realtime data is turned off

          if(this.line_chart.data)
            this.drawLineChart(this.line_chart.data, this.line_chart.options)


        }
      }
    },
    computed:{
      token(){
        return this.$store.getters.token
      },
      showChartBox(){
        return (this.type == 'line' || this.type == 'bar' || this.type == 'status')
      },


      modelUrl(){
        return `${this.$hostname}/model/chart/${this.model_identifier}`
      },


      ...mapState(["display"]),
      ...mapState(["realtime"]),

      realtimeChart(){
        return this.graphOptions.realtime && this.realtime.realtime && this.realtime.realtimeMaschine == this.maschine.name
      }
    },
    updated(){
      
    },
    mounted(){
      if(this.selfRequest){
        this.$nextTick(() => {
          this.change_date()
          
        })
      }
      if(this.chart.value){
        this.value = this.$props.chart.value
        
      }
      if(this.chart.unit)
        this.unit = this.$props.chart.unit

      window.addEventListener('resize', function(event){
        // do stuff here
        console.log('redraw');


      });
    }
}
</script>


<style>
.chart{
  flex: 1 0 0;
  flex-direction: column;
  
  border: 1pt solid var(--color5);
  background-color: var(--color1);

  border-style: solid;
  border-color: var(--color5)
}
.chart-wrapper{
  min-height: 350pt;
  width: calc(50% - 2pt - 10px);
  display: inline-flex;
  flex-direction: column;
  margin: 5px;
}
.drop-area.hover-over{
  background-color: lightblue;
}
.chart-wrapper.editor-mode{
  height: 370pt;
}
.chart-wrapper.double{
  width: calc(100% - 2pt - 20px);
}
.chart.ew_frame{
  border-width: 2pt;
  margin: calc(5px - 1pt);
}
.chart.edit{
  border-style: dashed;
  border-color: var(--color2)
}
.chart:not(.edit){
  border-style: solid;
  border-color: var(--color5)
}
.chart:nth-child(2n){
  margin-right: 2.5px;
}
.chart:nth-child(2n + 1){
  margin-left: 2.5px;
}
.chart:last-child:nth-child(even){
}

.chart header{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  padding: 2.5px;
  cursor: pointer;

}
.chart header p{
  margin: 0;
  font-size: var(--font1);
}
.chart header p.value{
  font-weight: bold;
}
.chart header .placeholder{
  flex: 1 0 0;
}
.chart .chartbox{
  flex: 1 0 0;
}
.chart .chartbox.xy-scrollable{
  overflow: scroll;
}

.chart footer{
  font-size: var(--font1);
  flex: 0 0 auto;

  display: flex;
  flex-direction: row;

  padding: 5px;
}
.chart footer .vdatetime input{
  font-size: 12pt;
  cursor: pointer;
  text-align: center;
  margin: auto;
}
.chart footer .placeholder{
  flex: 1 0 0;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .chart-wrapper{
    min-height: auto;
  }
  .chart{
    flex: 1 0 0;
    height: 100%;
    padding: 2.5pt;
    order: 1;
  }
  .chart .chart-selector{
    display: none;
  }
  .chart.double-width{
    width: calc(100% - (2pt + 10px + 5pt) * 2 + 10px );
    order: 2;
  }
  .chart header{
    flex-direction: column;
  }
  .chart header p{
    font-size: var(--font0);
    text-align: left;

  }
  .chart header p.value{
    font-size: var(--font4);
    text-align: center;
    margin: 5pt 0;
 }
  .chart header .info_values{
    font-size: var(--font0);
  }
}
</style>
<style>
.chart header .images{
  flex: 0 0 auto;
  height: 16pt;
  width: 18pt;
  display: flex;
  border-radius: 50%;
  margin: auto 5pt;
  flex: 0 0 18pt;
}
.chart header .images .icon{
  flex: 1 0 0;
  margin: 2pt;
}
.chart header .info.images{
  background-color: deepskyblue;
}
.chart header .info.images .icon{
  background-image: url('../images/status-icons/info-solid.svg');
}
.chart header .warn.images{
  background-color: rgb(221, 199, 0);
}
.chart header .warn.images .icon{
  background-image: url('../images/status-icons/exclamation-solid.svg');
}
.chart header .check.images{
  background-color: rgb(6, 184, 0);
}
.chart header .check.images .icon{
  background-image: url('../images/status-icons/check-solid.svg');
}
.chart header .error.images{
  background-color: rgb(224, 17, 17);
}
.chart header .error.images .icon{
  background-image: url('../images/status-icons/times-solid.svg');
}

</style>