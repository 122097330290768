import VueRouter from 'vue-router'

//HBBox
//import home from '@/views/hbbox.de/home.vue'
//Enwatmon
import home from '@/views/enwatmon.de/home.vue'


import mainViewVue from '@/views/main-view.vue'
import globalViewVue from '@/views/global-view/global-view.vue'
import globalViewDashboard from '@/views/global-view/global-dashboard-view.vue'
import globalViewNoftifiaction from '@/views/global-view/global-notification-view.vue'

import globalViewDashboardSettings from '@/views/global-view/settings/global-dashboard-settings-view.vue'
import globalViewDashboardCharts from '@/views/global-view/settings/global-dashboard-charts-view.vue'

import globalOfflineView from '@/views/global-view/global-offline-view'

import chartDetailsDialog from '@/views/dialog-views/chart-details-dialog.vue'

import maschines from '@/views/maschines.vue'
import my_maschine from '@/views/my_maschine.vue'
import loginViaUrlVue from '@/views/login-via-url.vue'
import settingsRouter from './settingsRouter.js'

import changePasswordDialog from '@/views/dialog-views/change-password-dialog.vue'

import myMaschineOverview from '@/views/myMaschine/maschine-overview.vue'
import kategoriesView from '@/views/myMaschine/kategorie-view.vue'


export default new VueRouter({
  mode:'history',
  routes: [
    { path: '/', component: home },
    { name: 'maschines', path: '/maschines', component: maschines, },
    { path: '/login-via-url/:token', component: loginViaUrlVue, props: true, },
    
    { name: 'change-user-settings', path: '/change-user-settings', component: changePasswordDialog, },
    {
      name: 'main', path: '/main', component: mainViewVue, redirect: { name: 'main-global-view'},  children: [
        { name: 'main-global-view', path: '/main/global', redirect: { name: 'main-global-notification' }, component: globalViewVue, children: [
            { name: 'main-global-dashboard', path: '/main/global/dashboard/:id', props: true, component: globalViewDashboard, children: [

                { name: 'main-global-dashboard-settings', path: '/main/global/dashboard/:id/settings', props: true, component: globalViewDashboardSettings, },
                { name: 'main-global-dashboard-charts', path: '/main/global/dashboard/:id/charts', props: true, component: globalViewDashboardCharts, },

                { name: 'main-global-dashboard-chart-details', path: '/main/global/dashboard/:id/chart-details/:chartId/:maschineId/:type/:html_id', props: true, component: chartDetailsDialog, },

              ] 
            },
            { name: 'main-global-notification', path: '/main/global/notification/:header?', props: true, component: globalViewNoftifiaction, },

            { name: 'main-global-offline', path: '/main/global/offline', props: true, component: globalOfflineView, },

          ]
        },
        settingsRouter,

        {
          name: 'my_maschine', path: '/my_maschine/:id/:name/', redirect: { name: 'maschine-overview-view'},  component: my_maschine, props: true, children: [
            { name: 'maschines', path: '/my_maschine/:id/:name/maschines', component: maschines, },

            { name: 'maschine-overview-view', path: '/my_maschine/:id/:name/overview', props: true, component: myMaschineOverview, },
            { name: 'kategorie-view', path: '/my_maschine/:id/:name/:kid/:kategorie', props: true, component: kategoriesView, },



          ]
        },
      
      ]

    },
  
    { path: '*', template: '<p>404</P>' } // Not found
  ]
})