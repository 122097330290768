<template>
  <div class="user-settings-maschines">
    <div class="vertical-wrapper" style="flex: 0.25 0 0">
        <div class="vertical-wrapper" style="flex: 0 0 auto; padding: 5pt"> 
          <md-field md-clearable style="flex: 0 0 auto; width: 100%; margin-bottom: 2px;" >
            <label for="">Suche</label>
            <md-input style="width: 100%;" v-model="searchText"></md-input>
          </md-field>
        </div>
        
        <div class="vertical-wrapper">
          <list :overflow="true" class="sidebar" :items="maschines.filter(e => e.text.toLowerCase().includes(searchText.toLowerCase()))" v-on:item-clicked="maschine_selected"  style="overflow-y: scroll;flex: 1 0 0px;"/>
        </div>
        <div style="flex: 0 0 auto">
            <md-button class="md-raised md-primary" v-on:click="addMaschine">Add</md-button>
        </div>
      </div>
    
  
    <div class="maschine-settings vertical-wrapper">
      <div class="overview" style="flex: 0 0 auto">
        <div class="horzontal_wrappper md-fields">
          <md-field>
            <label for="">name</label>
            <md-input v-model="maschine.name"></md-input>
          </md-field>
          <md-field>
            <label for="">MAD</label>
            <md-input v-model="maschine.mad"></md-input>
          </md-field>
          <md-field style="flex: 0.5 0 0;">
            <label for="">sending rate</label>
            <md-input type="numeric" v-model="maschine.sending_rate"></md-input>
          </md-field>
          <md-button v-on:click="save_maschines" class="md-primary save-button">save</md-button>
        </div>
        <div class="horizontal_wrapper">
          <md-switch v-model="maschine.maschineActive" class="md-primary" style="margin: 10pt;">Aktiv
          </md-switch>

          <md-switch v-model="maschine.allow_realtime" class="md-primary" style="margin: 10pt;">allowed realtime
          </md-switch>

           
          <div class="copy " v-on:click="$router.push({name: 'settings-maschine-copy-dialog'})">
            <div class="icon"></div>
            <p>copy from</p>
          </div>
        </div>
        <!--
        <list class="navbar" :items="config_items" />
        -->
      </div>

      <md-tabs v-on:md-changed="maschineTabChanged" style="max-width: 80vw">
        <md-tab md-label="Attributes" style="flex: 0 0 auto" class="vertical-wrapper" id="0">
          <div v-for="item in maschineAttributes" :key="item.key" class="horizontal_wrapper">
            <md-field style="width: auto; flex: 1 0 0;">
              <label for="">{{item.name}}</label>
              <md-input v-model="item.value"></md-input>
            </md-field>
            <div class="placeholder" style="flex: 2 0 0;"></div>
          </div>
          <div class="horizontal-wrapper">
            <md-button class="md-primary md-raised"
              v-on:click="$socket.emit('get-all-maschine-attributes', {token: token})">edit attributes
            </md-button>
            <div></div>
          </div>
        </md-tab>
          <!--

        <md-tab md-label="Data Uplink" id="1">
          <div class="vertical-wrapper" style="max-height: 60vh; max-width: 70vw; overflow-y: scroll;">
            <h4>S7 PUT/GET</h4>
            <div ref="uplink-s7-table" style="display: flex;">

            </div>
            <div class="horizontal-wrapper">
              <div></div>
              <md-button class="md-primary" v-on:click="upLinkS7Table.insertRow([-1, '', '', ''])">add</md-button>
              <div></div>
            </div>
          </div>


        </md-tab>

        <md-tab md-label="Data Downlink" id="2">
          <h4>S7 PUT/GET</h4>
          <div ref="downlink-s7-table" style="display: flex;">

          </div>
          <div class="horizontal-wrapper">
            <div></div>
            <md-button class="md-primary" v-on:click="downLinkS7Table.insertRow([-1, 0, maschine.id, '', ''])">add</md-button>
            <div></div>
          </div>
        </md-tab>
        -->

        <md-tab md-label="VPN Settings" id="3">
          <div class="horizontal-wrapper">
            <div class="vertical-wrapper">
                <md-field style="flex: 0 0 auto;">
                  <label for="">Virtual IP</label>
                  <md-input v-model="vpnSettings.virtualIP"></md-input>
                </md-field>
            </div>
            <div class="vertical-wrapper">
              <div></div>
            </div>
          </div>
          <div class="horizontal-wrapper">
            <div></div>
            <md-button class="md-primary md-raised" v-on:click="saveVPNSettings">save</md-button>
          </div>

        </md-tab>

        <md-tab md-label="Weather" class="vertical_wrapper" id="4">
          <div class="horizontal_wrapper">
            <md-switch v-model="weatherModuleEnabled">Weather Module On</md-switch>
            <div class="placeholder"></div>
          </div>
          <div v-for="item in locations" :key="item.key">
            <div class="horizontal_wrapper">
              <md-field style="width: auto; flex: 4 0 0;">
                <label for="">Street</label>
                <md-input v-model="item.street"></md-input>
              </md-field>
              <div style="flex: 0 0 24px"></div>
              <md-field style="width: auto; flex: 1 0 0;">
                <label for="">Nr</label>
                <md-input v-model="item.streetnr"></md-input>
              </md-field>
            </div>
            <div class="horizontal_wrapper">
              <md-field style="width: auto; flex: 1 0 0;">
                <label for="">Postalcode</label>
                <md-input v-model="item.postalcode"></md-input>
              </md-field>
              <div style="flex: 0 0 24px"></div>
              <md-field style="width: auto; flex: 2 0 0;">
                <label for="">City</label>
                <md-input v-model="item.city"></md-input>
              </md-field>
              <div style="flex: 0 0 24px"></div>
              <md-field style="width: auto; flex: 1 0 0;">
                <label for="">Country</label>
                <md-input v-model="item.country"></md-input>
              </md-field>
            </div>
          </div>


          <div class="horizontal_wrapper">
            <md-button class="md-primary md-raised"
              v-on:click="locations.push({id: -1, key: $uuid.v4(), street: '', streetnr: '', postalcode: '', city: ''})">
              add</md-button>
            <div class="placeholder"></div>
          </div>
        </md-tab>
      </md-tabs>


    </div>
    <router-view></router-view>
    <md-dialog :md-active.sync="showMaschineAttributeEditor">
      <md-dialog-title>maschine attributes</md-dialog-title>
      <div class="vertical_wrapper" style="margin: 0 24px; min-width: 380pt;">
        <div v-for="item in allAttributes.filter(e => !e.toDelete)" :key="item.key" class="horizontal-wrapper">
          <md-field style="flex: 1 0 0px; width: auto;">
            <label for="">Name</label>
            <md-input v-model="item.name"></md-input>
          </md-field>
          <div style="flex: 0 0 24px"></div>
          <md-field style="flex: 1 0 0px; width: auto;">
            <label for="">default</label>
            <md-input v-model="item.default_value"></md-input>
          </md-field>
          <div style="flex: 0 0 24px"></div>
          <div class="icon" :style="`
          flex: 0 0 48px; 
          cursor:pointer; 
          background-image: url(${require('../../images/trash-alt-solid.svg')}); 
          border: solid transparent 12px;`" v-on:click="deleteAttribute(item)">
          </div>

        </div>

        <div class="horizontal_wrapper">
          <div></div>
          <md-button class="md-primary md-raised"
            v-on:click="allAttributes.push({id: -1, key: $uuid.v4(), name: '', default_value: '', toDelete:false})">add
          </md-button>
          <div></div>
        </div>
      </div>
      <md-dialog-actions class="">
        <md-button class="md-primary" v-on:click="showMaschineAttributeEditor = false">close</md-button>
        <md-button class="md-primary" v-on:click="saveAllAttributes">save</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import vue from 'vue'
import { uuid } from 'vue-uuid'
import list from '@/components/VueComponents/list.vue'
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars

import { mapState } from 'vuex'

export default {
  name: 'user-settings-maschines',
  components:{ list, },
  props: { },
  methods: {

    maschine_selected(maschine){
      this.maschine_index = this.maschines.findIndex(e => e.id==maschine.id)

      this.maschine.name = maschine.text
      this.maschine.id = maschine.id
      this.maschine.active = true;
      this.maschine.maschineActive = maschine.maschineActive;


      this.$socket.emit('load-settings-maschine-details', {token: vue.$token, maschine:maschine})

      if (this.currentTab == 1 || this.currentTab == 2)
        this.$socket.emit('get-maschine-config', { token: this.token, maschine: this.maschine })

    },
    save_maschines(){
      this.$swal({
          icon:'question',
          title:'Speichern?', 
          text:'Änderungen hier haben physische Auswirkungen auf das ausgewählte Gerät', 
          showConfirmButton:true,
          showDenyButton: true,
          confirmButtonText: 'Save',
          denyButtonText: `Don't save`
      }).then(async (result) => {
        if (result.isConfirmed) {
          
          this.$socket.emit('save-maschine-settings', {token:vue.$token, maschine:this.maschine, maschineAttributes: this.maschineAttributes, locations: this.locations, mandate: this.currentMaschineMandate })
        } else if (result.isDenied) {
          this.$swal('Changes are not saved', '', 'info')
        }
      });
    },
    chose_item(item){
      this.copy_of_maschines.forEach(e => e.active = false);
      this.copy_maschine = item;
      item.active = true;
    },
    maschineTabChanged(tab){
      this.currentTab = tab

      if(tab == 1){
        this.$nextTick(() => {
          this.$refs['uplink-s7-table'].innerHTML = ''
          this.upLinkS7Table = jexcel(this.$refs['uplink-s7-table'], {
            data: [['', '', '', '',]],
            columns: [
              { title: 'id', type: 'hidden' }, 
              { title: 'valuename', width: 300 }, 
              { title: 'unit', width: 120 }, 
              { title: 'ip', width: 120 }, 
              { title: 'db', width: 120 }, 
              { title: 'offset', width: 120 }, 
              { title: 'datatype', width: 120 },
              { title: 'length', width: 120 }, 
            ],
            onafterchanges: this.upLinkDataChanged
          });
          this.$socket.emit('get-maschine-config', {token: this.token, maschine:this.maschine})
        })
      }else if(tab == 2){
        this.$nextTick(() => {
          this.$refs['downlink-s7-table'].innerHTML = ''
          this.downLinkS7Table = jexcel(this.$refs['downlink-s7-table'], {
            data: [['', '', '', '',]],
            columns: [
              { title: 'id', type: 'hidden' },
              { title: 'created', width: 100, type: 'dropdown', source:[{id: 0, name: 'no'}, {id:1, name:'yes'}] },

              { title: 'maschine', width: 300, type: 'dropdown', autocomplete: true, source: this.maschines.map(e =>{return {id: e.id, name: e.text }}) },
              { title: 'valuename', width: 300 },
              { title: 'unit', width: 120 },
              { title: 'ip', width: 120 },
              { title: 'db', width: 120 },
              { title: 'offset', width: 120 },
              { title: 'datatype', width: 120 },
              { title: 'length', width: 120 },

            ],
            onafterchanges: this.downLinkS7TableChanged
          });
          this.$socket.emit('get-maschine-config', { token: this.token, maschine: this.maschine })

        })
      }
    },
    
    upLinkDataChanged(){
      this.upLinkS7Data = this.upLinkS7Table.getData().filter(e => !isNaN(e[0]) ).map(e => {return {id: e[0], valuename: e[1], unit: e[2], ip: e[3], db: e[4], offset:e[5], datatype: e[6], length:e[7]}})
      this.$socket.emit('edit-maschine-config', { token: this.token, maschine: this.maschine, upLinkS7Data:this.upLinkS7Data, downLinkS7Data: this.downLinkS7Data })
    },
    downLinkS7TableChanged(){
      this.downLinkS7Data = this.downLinkS7Table.getData().filter(e => !isNaN(e[0])).map(e => { return { id: e[0], created: e[1], maschineID:e[2], valuename: e[3], unit: e[4], ip: e[5], db: e[6], offset: e[7], datatype: e[8], length: e[9] } })
      this.$socket.emit('edit-maschine-config', { token: this.token, maschine: this.maschine, upLinkS7Data: this.upLinkS7Data, downLinkS7Data: this.downLinkS7Data })

    },
    copy_from(){

    },
    deleteAttribute: async function(item){
      const { isConfirmed } = await this.$swal({
        icon: 'question',
        title: 'Are you sure?',
        text: 'delete maschine attributes',
        showDenyButton: true,
        showConfirmButton: true

      })
      if(!isConfirmed)
        return
      item.toDelete = true
    },
    saveAllAttributes(){
      this.$socket.emit('edit-all-maschine-attributes', {token: this.token, maschine:this.maschine, allAttributes: this.allAttributes})
      this.showMaschineAttributeEditor = false
    },

    async addMaschine(){
      /*
       Workflow for add Maschine:
       IF Admin == 2: set current mandate id on create and send on "save"
       IF Admin == 1: current mandate is my mandate id, but backend ignores mandante anyways
       IF Admin == 0: no access
      
      */
      this.currentMaschineMandate = await this.getMandate()

      this.maschine.name = '';
      this.maschine.text = '';

      this.maschine.id = -1;
      this.maschine.mad = uuid.v4();
      this.maschines.forEach(e => e.active = false);
      this.maschine.active = true;
      this.maschines.push(this.maschine)

      this.maschineAttributes.forEach(e => e.value = '')

    },


    getMandate(){
      return new Promise((resolve) => {
        if(this.mandates.length > 1){
          this.$swal({
            title: 'Neue Maschine?',
            icon: 'question',

            showConfirmButton: true,
            confirmButtonText: 'Erstellen',
            showDenyButton: true,
            denyButtonText: 'Abbrechen',

            html: `
          <p></p>
          <text>Möchten Sie eine neue Maschine erstellen?</text>
            <br><label>
              Mandat:
            </label>
            <select id="swal-input-mandate" class="swal2-input">
              ${this.mandates.length ? this.mandates.map((e, i) => `<option ${!i ? 'selected' : ''} value="${e.id}">${e.name}</option>`).join('\n') : ''}
            </select>

          <div style="margin-bottom: 5pt;"></div>
        ` ,
            focusConfirm: false,
            preConfirm: () => {
              const swalInputMandate = document.getElementById('swal-input-mandate')
              return {
                IDh_mandate: swalInputMandate ? swalInputMandate.value : 0
              }
            }

          }).then((value) => {
            if (value.isConfirmed){

              resolve(value.value.IDh_mandate)
            }

          })
        }else{
          resolve(this.mandates.length? this.mandates[0].id : 0)
        }

      })
    },

    saveVPNSettings(){
      this.$socket.emit('edit-maschines-vpn-settings', {token: this.token, maschine: this.maschine, vpnSettings : this.vpnSettings })
    }
  },
  
  beforeRouteEnter (to, from, next) { 
    next(vm => { 
        vm.$socket.emit('load-settings-maschine-list', {token: vm.token})
   
        next();
    }) 
  },

  sockets: {
    'show-settings-maschine-list': function(maschines){
      // loop thrugh array to enable fontend
      
      maschines.forEach(e => {
        // doubleing name
        e.maschineActive = e.active ? true: false;
        e.active = false; 
      });
      if(maschines.length){
        let index = 0;
        if (this.maschine_index > 0)
          index = this.maschine_index;

        maschines[index].active = true;
        this.maschine_selected(maschines[index])
      }
      this.maschines = maschines;
     
      this.copy_of_maschines = this.copy_maschines
    },
    'show-settings-maschine-details': function ({ this_maschine, maschineAttributes, locations, vpnSettings }){
      this.maschine.mad = this_maschine.mad
      this.maschine.allow_realtime = parseInt(this_maschine.allow_realtime) >= 1;

      //this.s7get_data = s7get.map(e => [e.valuename, e.unit, e.ip, e.db, e.offset, e.length, e.datatype])
      maschineAttributes.forEach(e => {e.key = this.$uuid.v4(); e.toDelete = false})
      this.maschineAttributes = maschineAttributes;

      locations.forEach(e => {e.key = this.$uuid.v4(); });
      this.locations = locations;

      //
      console.log(vpnSettings);
      this.vpnSettings = vpnSettings
    },
    'saved-maschine-settings': function(){
      //this.maschine_index = this.maschines.indexOf(this.maschine);
      this.$socket.emit('load-settings-maschine-list', {token: vue.$token})
    },
    'set-all-maschine-attributes': function({allAttributes}){
      this.showMaschineAttributeEditor = true

      allAttributes.forEach(e => { e.key = this.$uuid.v4(); e.toDelete = false })
      this.allAttributes = allAttributes
    },
   

  },
  
  data(){
    return{
      searchText: '',
      maschines:[],
      maschine: {id: -1, name: '', mad: '', allow_realtime:false, maschineActive: true},
      maschine_index: -1,
      currentTab: 0,
      config_items: [
          {id: 1, text:'data s7', active:true}, 
          {id: 2, text:'data gfs', active:false}, 
          {id: 3, text:'data andiDB', active:false}, 
          
          {id:6,text: 'vpn', active:false}],

      s7get_cols : [],
      s7get_data : [['', '', '',] ,],
      
      upLinkS7Table: 0,
      upLinkS7Data: [],

      downLinkS7Table: 0,
      downLinkS7Data: [],

      copy_elements: [
        {text: 'Charts', active:true},
        {text: 'RI Schemas', active:true},
        {text: 'Exports', active:true},
        {text: 'Valuenames', active:true},
        {text: 'Warning-Frames', active:true},

        {text: 'VPN Settings', active:true},
      ],
      vpn_settings: {enable: false, ip: '', subnet:''},

      copy_of_maschines: [],
      show_copy_dialog: false,
      copy_maschine: {},


      maschineAttributes: [],

      showMaschineAttributeEditor: false,
      allAttributes:[],


      weatherModuleEnabled: false,
      locations: [],

      currentMaschineMandate: 0,

      vpnSettings: { virtualIP: '', connected: false }
    }
  },
  computed: {
    ...mapState(['token', 'mandates', 'admin']),
    
    copy_maschines() {
        return JSON.parse(JSON.stringify(this.maschines.filter(e => e.id > 0 && e.id != this.maschine.id)));
    }
  }
}
</script>
<style>

.maschine-settings{
  flex: 1 0 0;
}
.maschine-settings .save-button{
  margin: auto 30pt;
  
}
.user-settings-maschines{
  display: flex;
  flex-direction: row;
}
.user-settings-maschines ul.sidebar li{
  padding: 6px 15pt;
  font-size: 14pt;
}


.horzontal_wrappper.md-fields{
  display: flex;
  flex-direction: row;
}
.horzontal_wrappper.md-fields > div{
  width: auto;
  margin: 10pt;
  flex: 1 0 0;
}
.user-settings-maschines .copy{
  margin: auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.user-settings-maschines .copy .icon{
  background-image: url('../../images/copy-solid.svg');
  flex: 0 0 40pt;

}
.user-settings-maschines .md-content.md-tabs-content.md-theme-default{
  flex: 1 0 0;
  height: auto;
}
</style>