<template>
  <div class="message-frame">
    <div class="images" :class="messageFrame.icon">
      <div class="icon"></div>
    </div>
    <div class="vertical_wrapper" style="margin-left: 10pt" >
      <p>{{messageFrame.header? messageFrame.header: ''}}</p>
      <div style="" :style="'color:' + ((messageFrame.text_color)? messageFrame.text_color:'#2c3e50') + ';'">
        {{messageFrame.text + ' ' + messageFrame.value}}
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: 'message-frame',
  props: {'message-frame':Object}
}
</script>
<style>
.message-frame{
  display: flex;
  margin: 5pt;
  border: 2pt solid var(--color5);
  background-color: var(--color1);
}
.message-frame .vertical_wrapper{
  flex: 1 0 0;
  flex-direction: column;
}
.message-frame .vertical_wrapper p{ 
  font-size: var(--font2);
  font-weight: bold;
  text-align: left;
}
.message-frame .images{
  flex: 0 0 auto;
  height: 40pt;
  width: 40pt;
  display: flex;
  border-radius: 50%;
  margin: auto 5pt;
}
.message-frame .images .icon{
  flex: 1 0 0;
  margin: 5pt;
}
.message-frame .info.images{
  background-color: deepskyblue;
}
.message-frame .info.images .icon{
  background-image: url('../images/status-icons/info-solid.svg');
}
.message-frame .warn.images{
  background-color: #ddc700;
}
.message-frame .warn.images .icon{
  background-image: url('../images/status-icons/exclamation-solid.svg');
}
.message-frame .check.images{
  background-color: rgb(6, 184, 0);
}
.message-frame .check.images .icon{
  background-image: url('../images/status-icons/check-solid.svg');
}
.message-frame .error.images{
  background-color: rgb(224, 17, 17);
}
.message-frame .error.images .icon{
  background-image: url('../images/status-icons/times-solid.svg');
}

.message-frame .burger.font28pt{
  flex: 0 0 28pt;
}
.message-frame .vertical_wrapper div{
  flex: 0 0 auto;
  padding: 7.5pt 0;
  text-align: left;
  margin: auto 0pt;
  font-size: 18pt;
}
.message-frame .burger.font28pt{
  margin: auto;
}

.message-frame .burger.font28pt div{
  background-color: #2c3e50;
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .message-frame .vertical_wrapper div{
    font-size: var(--font0);
  }
}
</style>