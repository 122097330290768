<template>
  <div>
    <header class="home">
      <div class="logo" style="flex: 1 0 0;"></div>
      <div class="placeholder"></div>
      <loginpage :open_at_start="$isMobile()" v-on:login="login" class="login" :regicode="false" v-on:register="register"/>
    </header>
    <div class="body">
      <galerie :images="galerie_images"/>
    </div>
    <div>
      <article-with-image :image="{image: require('../../images/enwatmon-homepage/Hochbehälter2_iphone12black_portrait.png'), animation: true}" 
          :layout="{height: 300}"
          :content="`
          <p>Wasserhaus in der Westentasche</p>
          <div>Mit Enwatmon haben sie, ortsunabhänig immer einen vollen Überblick über Ihre Wasserversorgung</div>`"/>

      <article-with-image 
        :image="{image: require('../../images/enwatmon-homepage/uf overview_iphone12black_portrait.png'), animation: true}" 
        :layout="{height: 300, right:true}"
        :content="`
          <p>Störfälle, Warnungen und Serviceempfehlungen</p>
          <div>Mit Enwatmon erhalten Sie personalisierte Benachrichtigungen über Warnungen und Störfälle.<br> Wir bieten Ihnen außerdem auf Ihre Wasseraufbereitung zugeschnittene Serviceempfehlungen an</div>`"/>
 
    </div>
    <div style="height: 600px"></div>
    <div class="site footer">
        <a href="https://www.enwat.de/impressum">Impressum</a><a href="#">AGB</a><a href="#">{{$translate('private policy')}}</a>
    </div>
  </div>
</template>
<script>
import vue from 'vue'
import loginpage from '@/components/VueComponents/loginpage.vue'
import galerie from '@/components/VueComponents/galerie.vue'

import article_with_image from '@/components/VueComponents/article-with-image.vue'

export default {
  name: 'Home-view',
  components: {
    loginpage, galerie, 'article-with-image':article_with_image
  },
  methods:{
    login({email, password}){
      this.$socket.emit('login', {username:email, password:password, isMobil: false, device_token: vue.$device_token})
    },
    register({ email, password, regicode}){
      console.log('Register', email);
      this.$socket.emit('register', {username:email, password:password, regicode:regicode})
      

      this.userdata = {email: email, password:password}
    }
  },
  sockets:{
   
    connect_error(e){
      console.log(e)
    },
    login_failed(){
      this.$swal({
          icon:'error',
          title:'Login failed', 
          text:'wrong login creditals', 
          denyButtonText: `Don't save`
      })
    }, 
    'register_sucessful': function(){
      this.login({email:this.userdata.email, password:this.userdata.password})
       this.$swal({
          icon:'success',
          title:'Register success', 
          text:'success', 
      })
    }


  },data(){
    return{
      galerie_images:[
        { image: require('../../images/IMG_8639.jpg'), text: 'Enwatmon, das Wasserhaus in der Westentasche'},
        { image: require('../../images/FAV_IMG_8669.jpg'), text: 'Zugriff auf Ihre Anlagen von Überall'},
        { image: require('../../images/IMG_8828.jpg'), text: 'Bequem Daten auswerten \nund analysieren'},

      ],
      userdata: {}
    }
  }, created(){
//    this.$dictonary.add(['Log in'])
    this.$dictonary.add(['private policy', 'Datenschutz'])


  }
}
</script>
<style>

header.home{
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  background-color: rgba(255,255,255,0.6);
  z-index: 100;
}
header.home .icon{
  flex: 1 0 0;
}
header.home .login{
  flex: 0 0 auto;
  margin-top: 10pt;
  margin-right: 10pt;
  margin-bottom: 10pt;
}
header.home .placeholder{
  flex: 2 0 0;
}
header.home .button:not(.login){
    background-color: rgba(255, 255, 255, 0.5);
    border-color: rgba(0, 0, 0, 0.8);
    color: rgba(0, 0, 0, 0.8);
}
header.home .toLogin .button:hover{
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.8);
}

.site.footer{
  background: #292929;
  color: white;

  display: flex;
  flex-direction: row;
  padding: 10pt 0;
}
.site.footer a{
  flex: 1 0 0;
  font-size: var(--font1);
  text-align: center;
  color: white;
  text-decoration: none;
}
</style>
<style>

</style>