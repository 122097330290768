<template>
    <div>
        <md-dialog :md-active.sync="show" style="overflow-y: scroll; ">>
            <div class="horizontal-wrapper" style="flex: 0 0 auto;">
                <md-dialog-title style="display: inline">Change Login Creditals</md-dialog-title>
                <div class="placeholder"></div>
                <md-dialog-actions>
                    <md-button class="md-primary" v-on:click="$router.push('/')" >Close</md-button>
                    <md-button class="md-primary" v-on:click="save" >Save</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="margin: 25pt">
                <md-field >
                    <label>Username:</label>
                    <md-input v-model="username"></md-input>
                </md-field>
                <md-field >
                    <label>Passwort:</label>
                    <md-input type="password" v-model="password"></md-input>
                </md-field>
                <md-field >
                    <label>Passwort wiederholen:</label>
                    <md-input type="password" v-model="password_again"></md-input>
                </md-field>
            </div>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'change-password-dialog',
    methods: {
        save(){
            if((!this.password == '') && this.password == this.password_again){
                this.$socket.emit('change-user-creditals', {token: this.token, username: this.username, password:this.password, disalbeAccess:this.disalbeAccess, urlAccessToken:this.urlAccessToken})
            }else{
                this.$swal({
                    icon:'error',
                    title:'Failed', 
                    text: 'Passwords are uneven', 
                })
            }
        }
    },
    beforeRouteEnter (to, from, next) { 
        next(vm => { 
            vm.$socket.emit('get-user-creditals', {token: vm.token})
            vm.show = true

            next();
        }) 
    },
    sockets:{
        'set-user-creditals': function({name}){
            this.username = name;
        },
        'change-user-creditals-success': function(){
            this.$swal({
                icon:'success',
                title:'Worked', 
                text: 'Change user creditals success', 
            }).then(() => {
                this.$router.push('/my_maschine/0/all')
            })
        },
        'change-user-creditals-failed': function({text}){
            this.$swal({
                icon:'error',
                title:'Failed', 
                text: text, 
            })
        }

    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        urlAccessToken(){
            return this.$store.getters.urlAccessToken
        }
    },
    data(){
        return{
            show: false,
            username: '',
            password: '', 
            password_again: '',
            disalbeAccess: true,
        }
    }
}
</script>