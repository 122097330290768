<template>
  <div class="maschines_wrapper">

    <ul class="maschines">
      <li v-if="filterActive" style="flex: 0 0 35pt;">
        <div style="flex: 0 0 5pt; background-color: var(--color3);"></div>

        <div class="icon"
          :style="`flex: 0 0 35pt; border: solid var(--color3) 2pt; background-image: url(${require('../images/filter-solid.svg')}); background-color: var(--color3);`"
          v-on:click="openFilter"></div>
        <div style="flex: 1 0 0; background-color: var(--color3);  "></div>
        <div class="placeholder" style="background-color: var(--color7);"></div>
      </li>
      <li v-if="filterActive" style="flex: 0 0 2pt">

        <div style="flex: 0 0 35pt; background-color: white; "></div>
        <div style="flex: 1 0 0; background-color: white; "></div>
        <div class="placeholder" style="background-color: var(--color7);"></div>
      </li>

      <li v-for="item in filteredMaschines" :key="item.key" :class="{'active':item.active, 'connect':item.connect}" 
        v-on:click="open_maschine(item)">
        <div style="flex: 0 0 5pt; background-color: var(--color3);"></div>
        <div class="img"></div>
        <p class="">{{item.text }}</p>
        <div class="placeholder"></div>
      </li>

    </ul>
    <div class="maschine_placeholder">
      <div class="maschine_grey_box"></div>
      <div class="placeholder"></div>

    </div>
    <filter-popup title="Maschine Filter" :show="showFilter" v-on:close="showFilter = false"
      :searchAttributes="searchAttributes" v-on:re-filter="saveFilter" />
  </div>
</template>
<script>
import filterPopupVue from '../components/filter-popup.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'maschines-view', 
  components: {
    'filter-popup':filterPopupVue
  },
  methods: {
    saveFilter(){
      this.$cookies.set('filter-settings', JSON.stringify(this.searchAttributes))
    },
    open_maschine(maschine){

      // Set specific maschine to active
      maschine.active = true;
    
      this.$emit('maschine-selected')

      this.$store.commit('display/set-burger-active', false)

      if(this.$router.currentRoute.path === `/my_maschine/${maschine.id}/${maschine.text}/`)
        return;

      this.$router.push({ name: 'maschine-overview-view', params:{id: maschine.id, name: maschine.text} })
      this.$socket.emit('load_kategories',{token: this.token, maschine:maschine, mobil: document.body.clientWidth < 1200});
    },
    openFilter(){
      this.showFilter = true
      this.searchAttributes = this.getMaschineSearchAttributes
    }
  },
 
  beforeRouteEnter (to, from, next) { 
    next(vm => { 
      vm.$socket.emit('load_maschines', {token: vm.token} )
      vm.$socket.emit('get-maschine-search-attributes', { token: vm.token })
      next();
    }) 
  }, 
  computed:{
    ...mapGetters(['token', ]),

    filterActive(){
      return this.maschines.length > 10
    },
    maschines(){
      return this.$store.getters.getMaschines
    },
    filteredMaschines(){
      // save store maschine search attributes
      const searchAttributes = this.getMaschineSearchAttributes

      // return filtered maschines
      return this.maschines.filter(maschine => {
        
        // return boolean value of filter
        return searchAttributes.reduce((arr, sA) => {
          // get current value of maschine attribute => for quick access
          const value = maschine[sA.name]

          // reduce arr and 
          // search attribute input can be empty 
          // if not the value has to be lowercase compare in maschine
          return arr && (sA.value == '' || (value && value.toLowerCase().includes(sA.value.toLowerCase())))
        }, true)
      })
    },
    getMaschineSearchAttributes(){
      return this.$store.getters.getMaschineSearchAttributes
    }
    
  },
  data(){
    return{
      maschines_list:[],
      maschineActiveInterval: 0,
      searchText: '',
      showFilter: false,

      searchAttributes: []
    }
  },
  mounted(){
    
  }
 
}
</script>
<style>
.maschines_wrapper.not_show{
  width: 0;
}
.maschines_wrapper{
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 42pt;

  position: fixed;
  z-index: 11;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100% - 42pt);
  min-height: calc(100% - 42pt);
  transition: width 0.4s;

}
.maschines_wrapper .home{
  flex: 0 0 32pt;
  margin: 4pt;
  background-image: var(--logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}
.maschine_placeholder{
  flex: 1 0 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: row;
}
.maschine_placeholder .maschine_grey_box{
  flex: 1 0 0;
  background-color: var(--color3);
  padding: 5pt;
  padding-left: 30pt;

}
.maschine_placeholder .placeholder{
   flex: 5 0 0; 
}
.maschines{
  flex: 0 0 1;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;

  background-color: transparent;

}
.maschines li{
  
  display: flex;
  font-size: var(--font1);
  cursor: pointer;
  flex: 0 0 auto;
  
}
.maschines li p{
  text-align: left;
  flex: 1 0 0; 
  font-size: var(--font1);
  background-color: var(--color3);
  color: var(--color1);
  padding: 5pt;

}
.maschines li .placeholder{
  flex: 5 0 0; 
  background-color: var(--color7);

}
.maschines li br{
    display: none;
}
.maschines li.active{
  background-color: var(--color2)
}
.maschines li .img{
  flex: 0 0 25pt;
  background-color: var(--color3);

}
.maschines li.connect .img{
  content: '';
  background-image: url('../images/wifi-white-solid.svg');
  width: 25pt;
  display: inherit;
  background-repeat: no-repeat;

  background-position: center;
}

.maschines li.connect.active .img{
  background-image: url('../images/wifi-white-solid.svg');
}
@media screen and (max-device-width: 800px) {/*für mobil*/
  .maschines_wrapper{
    top: 7vh;
    margin-top: 36pt;
    padding-bottom: 5vh;
    z-index: 11;
    border-top: solid 4pt var(--color2);  
    height: 70vh;
    z-index: 12;
  }
  
  .maschines{
    height: calc(100vh  - 10pt);
    overflow-y: scroll;
    padding-bottom: 20pt;

    background-color: var(--color3);
  }
  .maschines li .placeholder{
    flex: 0 0 0;
  }
  .maschines li p{
    padding: 5pt;
  }
  .maschines li .img{
    flex: 0 0 var(--font2);
  }
  .maschines_wrapper{
    width: 100%;
    transition: height 0.4s;
    
    height: calc(100% - 84pt);
    max-height: none;
  }
  .maschines_wrapper.not_show{
    height: 0;
  }
}
</style>
