<template>
    <div class="chart-header" ref="chart-header" style="flex: 0 0 auto" :class="alertIcon" v-on:click="e => $emit('click', e)" >
        <!--Classicl Desktop Display-->
        <div class="classic horizontal-wrapper" style="padding: 2.5px; font-size: 12pt;">
            <div v-if="alertIcon != ''" class="icon"><div class="symbol"></div></div>

            <p style="margin: auto 0;">{{ title }}</p>
            <div></div>
            <div style="flex: 0 0 auto; font-weight: bold; margin: auto 0;" v-for="v, i in values" :key="i + 'chartvalue' + title">
                {{ v + displayUnit(i) }}{{ ((values.length > 1 && i < (values.length -1 )) ? ', ' : '') }}
            </div>

        </div>
        <!--Mobil Display-->
        <div class="mobil vertical-wrapper">
            <div class="horizontal-wrapper">
                <div v-if="alertIcon != ''" class="icon"><div class="symbol"></div></div>
                <p style="text-align: left; margin: auto; margin-left: 0;">{{ title }}</p>
            </div>
            <div></div>
            <div class="horizontal-wrapper value-wrapper" ref="value-wrapper" style="flex-wrap: wrap; justify-content: center; margin: 5px 0;">
                <template v-for="v, i in values" >
                    <div :key="'value' + i + title + 1">{{ v }}</div>
                    <div :key="'value' + i + title + 2">{{ displayUnit(i) }}</div>
                    <div :key="'value' + i + title + 3">{{ ((values.length > 1 && i < (values.length -1 )) ? ', ' : '') }}</div>
                </template>

            

            </div>
            <div class="vertical-wrapper info">
                <p>max: <span>{{maxValues}}</span></p>
                <p>min: <span>{{minValues}}</span></p>
                <p>avg: <span>{{avgValues}}</span></p>
            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: 'chart-header',
    props: { 
        title: String, 
        values: [Array, String], 
        units: [Array, String], 
        maxValues:[Array, String], 
        minValues:[Array, String], 
        avgValues:[Array, String], 
        alertIcon: [Array, String]
    },
    computed:{
     
    },
    watch:{
        values(){
            this.checkOverflow()
        }
    },
    methods:{
        displayUnit(index){
            if(index + 1 >= this.units.length)
            return this.units[index]? this.units[index]: ''
            
            return this.units[index + 1] == this.units[index]? '': (this.units[index]? this.units[index]: '')
        },
        checkOverflow() {
            const element = this.$refs['value-wrapper'];
            if (element.scrollWidth > element.clientWidth) {
                this.overflowValue = true
                // You can add logic here to handle the overflow, e.g., shrink font size or show a tooltip
            } else {
                this.overflowValue = false

            }
        },
    },
    data(){
        return{
            overflowValue: false,
        }
    }
}
</script>

<style>
.chart-header .classic{
    display: flex;
}
.chart-header .mobil{
    display: none;
}
.chart-header .info p{
    text-align: left;
}
.chart-header .value-wrapper div{
    flex: 0 0 auto; font-weight: bold; font-size: 16pt;

}


.chart-header .icon{
    flex: 0 0 18pt;
    height: 18pt;
    margin: 2pt;
    border-radius: 8pt;
    padding: 3pt;
    margin-right: 4pt;

}
.chart-header .icon .symbol{
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
.chart-header.info .icon{
  background-color: deepskyblue;
  mask-image: url('../images/status-icons/info-solid.svg');
}
.chart-header.info .icon .symbol{
  background-image: url('../images/status-icons/info-solid.svg');
}
.chart-header.warn .icon{
  background-color: rgb(221, 199, 0);
}
.chart-header.warn .icon .symbol{
  background-image: url('../images/status-icons/exclamation-solid.svg');
}
.chart-header.check .icon{
  background-color: rgb(6, 184, 0);
}
.chart-header.check .icon .symbol{
  background-image: url('../images/status-icons/check-solid.svg');
}
.chart-header.error .icon{
  background-color: rgb(224, 17, 17);
}
.chart-header.error .icon .symbol{
  background-image: url('../images/status-icons/times-solid.svg');
}

@media screen and (max-device-width: 800px) {/*für mobil*/
    .chart-header .classic{
        display: none;
    }
    .chart-header .mobil{
        display: flex;
    } 
}
</style>

<style>
  .scroll-container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }

  .scroll-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 10s linear infinite;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
</style>