<template>
  <div class="horizontal-wrapper" style="
      flex: 0 0 42pt; 
      background-color: var(--color4);
      border-bottom: solid 2pt var(--color3);
      z-index: 20;">

    <div :class="{'hide':!show_header()}" class="user-header" style="flex: 0 0 100vw">
      <burger :active="true" class="burger font42pt" v-model="display.burgerActive" v-on:open="show_maschines" v-on:close="hide_maschines" ref="burger"/>
      <div class="icon logo" v-on:click="load_all_maschines()"></div>
      <div class="placeholder"></div>
      <div class="user" v-on:click="user_menu_open = !user_menu_open"></div>
      <div :class="{'hide':!user_menu_open}" class="my_user_list">
        <div class="icon editing" v-on:click="open_editor"></div>
        <div class="icon settings" v-on:click="open_settings"></div>
        <div class="icon logout" v-on:click="logout"></div>
      </div>
    </div>
    <div style="flex: 1 0 0"></div>

  </div>

</template>
<script>
import vue from 'vue'
import burger from '@/components/VueComponents/burger.vue'

import { mapState } from 'vuex';

export default {
  name:'user-header',
  components:{
    burger
  },
 
  methods:{
    load_all_maschines(){
      this.$router.push({ name: 'main' }); 
      this.$socket.emit('overview-ew-frames', {token:this.token})
    },
    show_maschines(){
      this.$emit('show_maschines', true)
      this.$socket.emit('load_maschines', {token: this.token})

    },
    hide_maschines(){

      this.$emit('show_maschines', false)
    },
    open_editor(e){
        this.$emit('open-editor', e)
    },
    open_settings(){
      this.user_menu_open = false
      this.$router.push({name:'settings-view'})
      //this.$emit('open-user-settings')
    },
    logout(){
      this.$router.push('/')
      vue.$token = ''
      this.$cookies.set('user-token', '')
    },
    close_burger(){
      //this.$refs.burger.close_burger()
    },
    open_burger(){
      //this.$refs.burger.open_burger()
    }

    
  },
  watch:{
    user_menu_open(){
      console.log(this.pushManagerStatus, this.pushManagerRegistration);
    }
  },
  computed:{
    token(){
      return this.$store.getters.token
    },
    pushManagerRegistration(){
            return this.$store.getters.getPushManagerRegistration;
    },
    pushManagerStatus(){
          return this.$store.getters.getPushManagerStatus;
    },
    ...mapState(['display'])
  },
  data(){
    return{
      show_header(){
          if (this.$router.history.current.path == '/my_maschine/0/all')
            this.$socket.emit('overview-ew-frames', {token: this.token})

          return  this.$router.history.current.path != '/'},
      user_menu_open: false
    }
  }, 
}
</script>
<style>
.hide{
  display: none !important;
}
div.user-header{
  height: 42pt;
  flex: 0 0 42pt;
  display: flex;
  flex-direction: row;

}
.user-header .burger{
  padding: 0 5pt;
}
.user-header .burger div{
  background-color: var(--color1);
}
.user-header .placeholder{
  flex: 1 0 0;
}
.user-header .user{
  flex: 0 0 42pt;
  border: 2pt solid var(--color4);
  background-color: var(--color5);
  border-radius: 50%;
  margin-right: 15pt;
  background-image: url('../images/user-solid-grey.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.my_user_list{
  position: absolute;
  right: 0;
  top: 40pt;
  width: 270pt;
  height: 50pt;
  background-color: var(--color4);
  border: 2pt solid var(--color3);
  border-top-color: var(--color4);
  z-index: 12;
  display: flex;
  flex-direction: row;
}
.my_user_list div.icon{
  flex: 1 0 0;
  margin: 5pt 20pt;
  cursor: pointer;
}
.my_user_list div.editing{
  background-image: url('../images/pen-solid.svg');
}
.my_user_list div.settings{
  background-image: url('../images/cog-solid.svg');
}
.my_user_list div.logout{
  background-image:url('../images/sign-out-alt-solid.svg');

}
.user-header .logo{
  flex: 0 0 200pt;
}

@media screen and (max-device-width: 800px) {/*für mobil*/
  .user-header{
    position: sticky;
  }
  .my_user_list{
    width: 90pt;
  }
  .my_user_list div{
    margin: 5pt;
  }
  .my_user_list div.settings{
    display: none;
  }
  .my_user_list div.editing{
    display: none;
  }
  .user-header .logo {
    flex: 0 0 120pt;
  }
}
</style>