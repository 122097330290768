<template>
    <div style="overflow-y: scroll; padding-bottom: 20pt">
        <maschine-ew-frame v-for="maschine in maschinesNotifications" :key="maschine.key" :maschine="maschine" :ewframes="maschine.ewframes" :filter="''"/>

    </div>
</template>
<script>
import maschineEwFrameVue from '../../components/maschine-ew-frame.vue'
export default {
    name: 'global-notification-view',
    props: { 'header':String },
    components: { 'maschine-ew-frame':maschineEwFrameVue },
    computed: {
        maschinesNotifications(){
            const list = this.messageFrames
            return list.reduce((arr, n) => {
                let m = arr.find(e => e.name == n.maschine_name)
                if(!m){
                    m = { id: n.id, name: n.maschine_name, header: n.header, ewframes: [ n ], key: this.$uuid.v4() }
                    arr.push(m)
                }else{
                    m.ewframes.push(n)
                }
                return arr
            }, [])

        },

        messageFrames(){
            const list = this.$store.getters.getMessageFrames.filter(e => (!this.header) || e.header == this.header )
            return list
        },
        token(){
            return this.$store.getters.token
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // set all menu opener values to false after entering a new maschine
            vm.$store.commit('display/set-show-maschines', false)
            vm.$store.commit('display/set-show-kategories', false)
            vm.$store.commit('display/set-burger-active', false)
            //
            vm.$socket.emit('overview-ew-frames', { token: vm.token })


        })
    },
    beforeRouteUpdate(to, from, next) {
        // set all menu opener values to false after entering a new maschine
        this.$store.commit('display/set-show-maschines', false)
        this.$store.commit('display/set-show-kategories', false)
        this.$store.commit('display/set-burger-active', false)
        this.$socket.emit('overview-ew-frames', { token: this.token })

        next()
    },
}
</script>