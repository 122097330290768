<template>
    <md-dialog :md-active="show" class="" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <div class="horizontal_wrapper" style="margin: 0 20pt">
            <md-dialog-title style="padding-left: 0">{{title}}</md-dialog-title>
            <div class="placeholder"></div>
            <md-button style="margin: auto;" class="md-primary md-raised" v-on:click="searchAttributes.forEach(e => e.value = '')">clear</md-button>
        </div>
        <div style="min-width: 400px; overflow-y:scroll; padding: 20pt">
            <div v-for="sa in searchAttributes" :key="sa.key">
                <md-field>
                    <label for="">{{ sa.text}}</label>
                    <md-input v-model="sa.value"></md-input>
                </md-field>
            </div>
        </div>

        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="changeSearchAttributes(); $emit('close')">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>

export default {
    name: 'filter-popup',
    props: { title: String, show: Boolean, searchAttributes:Array},
    watch:{
      
    },
    methods:{
        changeSearchAttributes(){
            this.searchAttributes.forEach(e => {
                e.evaluate = function (name, value, item) {
                    return item[name] != undefined? item[name].toString().includes(value) : value == ''
                }
            })
            this.$emit('re-filter')
        }
    },
    mounted(){
        this.changeSearchAttributes()
       
    },
    data(){
        return{
            searchText: '',
           
        }
    }
}
</script>