<template>
  <div class="user-settings-groups" >
    <list :items="groups" class="groups_list sidebar" v-on:item-clicked="group_selected"/>
    <div class="details_wrapper">
      <div class="name_wrapper vertical-wrapper">
        <p>Group:</p>
        <md-field>
          <label for="">Name</label>
          <md-input v-model="group.name" v-on:blur="data_change"></md-input>
        </md-field>
        <div>
          <md-button class="md-accent md-raised" v-on:click="removeGroup">Remove</md-button>
        </div>
      </div>
      <div class="maschine_wrapper">
        <p>Maschines:</p>

        <checkbox v-for="item in maschines" :key="item.id" :text="item.text" :value="+item.active" class="font-20pt" v-on:input="(e) => {item.active = e; data_change()}"/>
      </div>
    </div>
  </div>
</template>
<script>
import list from '@/components/VueComponents/list.vue'
import checkbox from '@/components/VueComponents/checkbox.vue'
 

export default {
  name: 'user-settings-groups',
  props: { },
  components: {list, checkbox},
  computed: {
    token() {
      return this.$store.getters.token
    }
  },
  methods:{
    group_selected(group){
      this.groups.forEach(e => e.active = false)
      group.active = true
      if(group.id < 0){
        this.group.name = ''
        this.group.id = -1
        this.maschines.forEach(e => e.active = false)
      }
      this.$socket.emit('load_settings_groups_details', {token: this.token, group: group})

    },
    change(){
      console.log('change');
    },
    data_change(){
      this.currentGroupIDs = this.groups.map(e => e.id)
      this.$nextTick(() => {
          this.$socket.emit('save_settings_groups_details', {
          token: this.token,
          group: this.group,
          maschines: this.maschines,
          reload: this.group < 0
        })
      })
    
    },
    async removeGroup(){
      const {isConfirmed} = await this.$swal.fire({
        icon: 'question', title: 'Gruppe entfernen?', text: `Nutzer haben vielleicht keinen Zugang zu ihren Maschinen`,
        showConfirmButton: true, confirmButtonText: 'Ja',
        showDenyButton: true, denyButtonText: 'Nein'
      })
      if(isConfirmed)
        this.$socket.emit('remove-settings-group', {token: this.token, group: this.group})
    }
  },
  beforeRouteEnter (to, from, next) { 
    next(vm => { 
        vm.$socket.emit('load_settings_groups', {token: vm.token})
   
        next();
    }) 
  },

  sockets:{
    show_settings_groups(groups){
      groups.forEach((e, i) => e.active = !i);
      this.groups = groups
      if(groups.length){
        this.$nextTick(() => {
          let m = this.groups.find(e => !this.currentGroupIDs.includes(e.id))
          if(!m)
            m = this.groups[0]

          this.group_selected(m)
        })
      }
      this.groups.push({id: -1, text: '+'})


    },
    show_settings_groups_details({group, maschines}){
      this.group = group;
      this.maschines = maschines
    }
  },
  data(){
    return{
      groups: [],
      group: {name: '', },
      maschines: [],
      currentGroupIDs: []
    }
  } 
}
</script>
<style>
.user-settings-groups{
  flex: 1 0 0; 
  font-size: var(--font1);

  display: flex;
  flex-direction: row;
}
.user-settings-groups .groups_list{
  display: 0 0 auto;
  display: flex;
  flex-direction: column;;
}
.user-settings-groups .groups_list:last-child{
  text-align: center;
}
.user-settings-groups .details_wrapper{
  flex: 1 0 0;  
  display: flex;
  flex-direction: row;
}
.user-settings-groups .details_wrapper > div{
  flex: 1 0 0;
  border: solid 2pt var(--color5);
  margin: 5pt;
  padding: 2pt;
}
.user-settings-groups p{
  text-align: left;
}
.user-settings-groups label{
  text-align: left;
  display: flex;
  flex-direction: column;
}
.user-settings-groups .maschine_wrapper{
  overflow-y: scroll;
  max-height: calc(100vh - (5vh + 105pt));
}
.user-settings-groups input{
  font-size: var(--font1);
  width: 400pt;
}
</style>