<template>
  <div class="maschine-ew-frame" >
    <div class="header" v-on:click="details = !details">
      <p class="">
            {{maschine.name}}
      </p>
      <div class="opener" :class="{'details':details}" >
        <div class="icon">

        </div>
      </div>
    </div>
    <div class="ew-frames" :class="{'show':details}">
      <message-frame :class="{'hide':!details}" v-for="frame, i in ewframes.filter(e => filter=='' || e.header == filter)" :key="i + 'all-maschines-ew-frame'" :message-frame="frame"/>
    </div>
    

  </div>
</template>
<script>
import message_frame from '@/components/message-frame.vue'

export default {
  name: 'maschine-ew-frame',
  components: {'message-frame':message_frame},
  props: {maschine:Object, ewframes:Array, filter:String},

  data(){
    let mydetails = this.$props.ewframes.length? true:false;
    return{
      details: mydetails
    }
  }
}
</script>
<style >
.maschine-ew-frame{
  display: flex;
  flex-direction: column;
}
.maschine-ew-frame > .header{
  display: flex;
  flex-direction: row;
  cursor: pointer;

  flex: 0 0 auto;
}
.maschine-ew-frame > .header > p{
  font-size: var(--font2);
  text-align: left;
  margin: auto 20pt;
  flex: 1 0 0;
}
.maschine-ew-frame > .header .opener{
  flex: 0 0 50pt;
  height: 50pt;
  transform: rotate(90deg);
  transition: transform .4s;
  display: flex;
  margin-left: 0;
}
.maschine-ew-frame > .header .opener.details{
  transform: rotate(0deg);
}
.maschine-ew-frame > .header .opener .icon{
  flex: 1 0 0;

  background-image: url('../images/angle-down-solid.svg');
}
.maschine-ew-frame .ew-frames{
  height: 0;
  transition: .4s height;
  border-bottom: solid 2pt var(--color5);
  margin: 0 20pt;
}
.maschine-ew-frame .ew-frames.show{
  height: auto;

}
</style>